<template>
<div class="col-12">
  <div style="background-color:#ffffff;border-radius:8px" >

    <!-- board page-->
    <div class="container">
    <!-- board -->
      <div style="overflow:auto;border-radius:8px" class="pc">
        <div class="board-header flex">
          <div class="bh-number body4-bold" style="line-height:50px">번호</div>
          <div class="bh-title body4-bold" style="line-height:50px">주문전환 방식</div>
          <div class="bh-name body4-bold" style="line-height:50px">성함</div>
          <div class="bh-date body4-bold" style="line-height:50px">신청날짜</div>
        </div>
        <div class="board-content flex" v-for="(item,idx) in list" :key="`item-${idx}`">
          <div class="b-number subtitle7" style="line-height:50px">{{ item.id }}</div>
          <div class="b-title subtitle7" style="line-height:50px">[PRO / 완전맞춤형] 서비스 신청 문의 드립니다</div>
          <div class="b-name subtitle7" style="line-height:50px">{{ item.name }}</div>
          <div class="b-date body4" style="line-height:50px">{{ item.created_time }}</div>
        </div>

      </div>
    <!--  -->

    </div>


  </div>
  <div class="container">
    <div class="mobile tablet">
      <div class="flex-align body6 mobile-board-content" v-for="(item,idx) in mList" :key="`item-${idx}`">
        <div class="col-6 sub ellipsis-2">[PRO / 완전맞춤형] 서비스 신청 문의 드립니다</div>
        <div class="col-3 text-center sub3" style="padding:0 8px">{{ item.name }}</div>
        <div class="col-3 text-center sub3">{{ datesFormat(item.created_time, 'date_3') }}</div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  components: {
  },
  data () {
    return {
      list: [
      ],
      mList: [],
      listFiltered: {
        cur_page: 1,
        page_num:1,
        total_page:1,
        page_length: 10
      }
    }
  },
  created () {
    this.getData();
  },
  methods: {
    getData () {
      this.$axios.get('public/launchpack/service/inquiry', {
        params: this.listFiltered
      }).then(res=>{
        this.list = res.data.data;
        this.mList = res.data.data.slice(0,6);
        this.listFiltered.page_length = res.data.page_length;
        this.listFiltered.cur_page = res.data.cur_page;
        this.listFiltered.total_page = res.data.total_page;
      });
    }
  }
}
</script>

<style scoped>

.tab-title{


  font-size: 30px;
  font-weight: 700;
  line-height: 1.25;
  text-align: left;
  color: #393e46;
  margin: 20px 0;


}

.tab-content{

  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: #222831;
}

.tab-image{
    height:210px;

}


.board-header{
  width:1152px;
  background-color: #f7f7f7;
  text-align:center;
  font-size: 16px;
  font-weight: 600;
  height:50px;
  line-height: 50px;


}

.bh-number{
  width:15%;

}
.bh-title{
  width:50%;
  text-align: left;
}
.bh-name{
  width:15%;

}
.bh-date{
  width:20%;

}

.board-content{
  width:1152px;
  text-align:center;
  font-size: 16px;
  font-weight: 500;
  height:50px;
  line-height: 50px;
  border-bottom: 2px solid #f7f7f7;

}

.b-number{
  width:15%;
  color: #a0a0a0;

}
.b-title{
  width:50%;
  text-align: left;
}
.b-name{
  width:15%;
  color: #a0a0a0;

}
.b-date{
  width:20%;
  color: #a0a0a0;

}
.mobile-board-content{
  margin-bottom: 8px;
  padding: 16px 12px;
  border-radius: 4px;
  background-color: white;
}
@media(max-width:600px){
  .bh-number, .b-number{
    display: none;
  }
  .board-header, .board-content{
    width:600px;
  }

  .bh-title, .b-title{
    width:50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin:0 0 0 20px;
  }
  .bh-title, .b-title,
  .bh-name, .b-name,
  .bh-date, .b-date {
    font-size: 12px
  }

}



</style>
